import React from "react"
import Section from "../../Elements/Section"
import ClinicInformation from "../ClinicInformation"

const ClinicsInformation = ({ values }) => (
  <Section title="Clinic Information" id="clinic-information">
    <h5 className="mt-2">Primary Office</h5>
    <ClinicInformation
      type="primary"
      values={values?.primaryClinic}
      isRequired
    />
  </Section>
)

export default ClinicsInformation
